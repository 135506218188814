.skeleton {
    opacity: .7;
    animation: skeleton-loading 1s linear infinite alternate;
  }
  .skeleton_descreption {
    opacity: .7;
    animation: skeleton-loading 1s linear infinite alternate;
    width: 329px;
    height: 48px;
    border-radius: 4px;
  }
  .skeleton_name {
    opacity: .7;
    animation: skeleton-loading 1s linear infinite alternate;
    width: 110px;
    height: 20px;
    border-radius: 4px;
  }
  
  .skeleton_buttons {
    opacity: .7;
    animation: skeleton-loading 1s linear infinite alternate;
    width: 162px;
    height: 32px;
    border-radius: 4px;
    margin: auto;
  }

  .skeleton_types {
    opacity: .7;
    animation: skeleton-loading 1s linear infinite alternate;
    width: 49px;
    height: 19px;
    border-radius: 5px;
    margin: auto;
  }

  
  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 70%);
    }
  
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }