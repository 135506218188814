.slide-in-elliptic-top-fwd {
	-webkit-animation: slide-in-elliptic-top-fwd 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-elliptic-top-fwd 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes slide-in-elliptic-top-fwd {
    0% {
      -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
              transform: translateY(-600px) rotateX(-30deg) scale(0);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0) rotateX(0) scale(1);
              transform: translateY(0) rotateX(0) scale(1);
      -webkit-transform-origin: 50% 1400px;
              transform-origin: 50% 1400px;
      opacity: 1;
    }
  }

  @keyframes slide-in-elliptic-top-fwd {
    0% {
      -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
              transform: translateY(-600px) rotateX(-30deg) scale(0);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0) rotateX(0) scale(1);
              transform: translateY(0) rotateX(0) scale(1);
      -webkit-transform-origin: 50% 1400px;
              transform-origin: 50% 1400px;
      opacity: 1;
    }
  }


  .scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes scale-up-center {
        0% {
          -webkit-transform: scale(0.5);
                  transform: scale(0.5);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
      @keyframes scale-up-center {
        0% {
          -webkit-transform: scale(0.5);
                  transform: scale(0.5);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
      

      .upToDown{
              animation: 2s ease-in-out upDown;
              animation-iteration-count: infinite;
              animation-direction: alternate;
      }

      @keyframes upDown {
        0%{
                transform: translateY(-3rem);
        }

        100%{
                transform: translateY(1rem);
        }
      }