
.server-continer {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  
  .server {
    display: flex;
    flex-direction: column;
    width: 45%;
  }
  
  .server .server-banner {
    width: 100%;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    -webkit-mask: -webkit-gradient(linear, center top, center bottom, color-stop(0.00, rgba(0, 0, 0, 1)), color-stop(1.35, rgba(0, 0, 0, 1)), color-stop(0.50, rgba(0, 0, 0, 1)), color-stop(1.65, rgba(0, 0, 0, 1)), color-stop(1.00, rgba(0, 0, 0, 0)));
    background-clip: border-box;
  }
  
  .server-info2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .server-info2 img {
    width: 20%;
    border-radius: 20px;
    border: 8px solid #1F212D;
  }
  
  .server-info2 a {
    color: #8992cc !important;
    font-size: 30px;
  }
  
  .buttons {
    display: flex;
    align-items: center;
  }
  
  .button1 {
    background-color: #FF4AA1;
    width: 130px;
    height: 34px;
    color: #fff;
    border: none;
    padding-left: 29px;
    padding-right: 26px;
    border-radius: 7px;
    margin-right: 10px;
  }
  
  .button2 {
    background-color: transparent;
    border: 3px solid #252836;
    width: 130px;
    height: 37px;
    color: #fff;
    padding-left: 26px;
    padding-right: 26px;
    border-radius: 7px;
  }
  
  .button3 {
    background-color: transparent;
    border: 3px solid #252836;
    width: 90px;
    height: 37px;
    color: #fff;
    top: 0px;
    margin-bottom: 16px;
    padding-left: 26px;
    padding-right: 26px;
    border-radius: 7px;
  }
  
  @media only screen and (max-width: 1020px) {
    .server-info2 {
      flex-direction: column;
    }
    .buttons {
      width: 95%;
      margin-top: 10px;
    }
    .button1 {
      width: 160px;
    }
    .button2 {
      width: 180px;
    }
    .server-description-continer {
      flex-direction: column;
    }
    .description {
      width: 100% !important;
    }
    .info {
      width: 100% !important;
      margin-left: 0px !important;
      margin-top: 10px;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .server {
      width: 90%;
    }
  }
  
  .server-icon {
    display: flex;
    align-items: center;
  }
  
  .tags a {
    font-size: 13px;
    background-color: #252836;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 7px;
    margin: 3px;
  }
  
  .server-description-continer {
    display: flex;
    width: 100%;
    margin-top: 10px;
  }
  
  .description {
    height: 150px;
    width: 70%;
    background-color: #252836;
    color: #8992cc !important;
    padding: 20px;
    border-radius: 10px;
  }
  
  .swal-wide{
    width:850px !important;
  }

  .info {
    width: 30%;
    height: 150px;
    background-color: transparent;
    border: 5px solid #252836;
    margin-left: 15px;
    border-radius: 10px;
    color: #8992cc !important;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  
  .badges a {
    font-size: 13px;
    background-color: #252836;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 7px;
    margin: 3px;
  }