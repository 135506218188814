:root{
    --primary-color: #ff4aa1;
    --secondery-color: #2b2d3b;
}

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body{
    background-color: #1f212d;
    font-size: 1rem;
    color: #fff;
    font-family: 'Titillium Web', sans-serif;
    overflow-x: hidden;
}

.navbar-brand{
    font-size: 1.3rem;
    line-height: 14px;
}

.navbar-brand::before{
    content: "";
    width: 38px;
    height: 38px;
    border-radius: 50%;
    position: absolute;
    box-shadow: var(--primary-color) 0px 0px 75px;
    animation: 4s linear 0s normal anml1blu;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.navbar-logo{
    right: 10px;
    bottom: 1px;
}

@keyframes anml1blu {
    0%{
        box-shadow: var(--primary-color) 0px 0px 20px; 
    }

    50%{
        box-shadow: var(--primary-color) 0px 0px 45px; 
    }
    
    75%{
        box-shadow: var(--primary-color) 0px 0px 65px; 
    }

    100%{
        box-shadow: var(--primary-color) 0px 0px 75px; 
    }
}

.navbar-collapse{
 margin-left: 3%;
}

.brand-dot{
    color:  var(--primary-color);
}

.btn:active, .btn:focus{
    box-shadow: none;
}

.nav-item{
    margin-left: 4%;
}

.active-nav-item{
    border-bottom: 1px solid #ff4aa1;
    color: rgba(255, 255, 255, 0.856) !important;
}

.btn-primary-custom{
    background-color: var(--primary-color);
    color: #fff;
    border-radius: 7px;
    padding: 6px 18px; 
    transition: 0.2s all;
    box-shadow: 272px 22px 250px 48px #e23388;
}

.btn-primary-custom:hover{
    color: #fff;
    background-color: #e23388;
    transition: 0.2s all;
}

.nav-bar-section{
    border-bottom: 1px solid #333746;
    padding-bottom: .2rem;
}

.dropstart{
    margin-right: 2%;
}

.dr-tg {
    background-color: transparent;
    border: none;
}
  .dr-tg:focus, .dr-tg:hover, .dr-tg:active {
    background-color: transparent;
    box-shadow: none;
}

  .dropdown-menu{
    background-color: #262836;
    border: 1px solid #262836 !important;
}
  .dropdown-item:hover{
    background-color: #313344;
}

/* Typo Graphy */

.typography-container{
    margin-top: 10%;
}

.tag-descreption{
    color: var(--primary-color);
    text-transform: uppercase;
    box-shadow: -142px 122px 350px 18px #e23388;
}

.main-title{
    letter-spacing: 1px;
    font-size: 2.9rem;
}

.title-descreption{    
  color: #b0b4c7;
  font-size: 1.1rem;
}

.btn-Invite, .btn-dashboard{
    background-color: var(--primary-color);
    height: 54px;
    padding-right: 26px;
    padding-left: 26px;
    border-radius: 7px;
}

.btn-Invite:hover{
    background-color: #e23388;
}

.btn-dashboard{
    background-color: var(--secondery-color);
}

.staics-section{
    background-color: #282a38;
    margin-top: 14%;
    padding-top: 10rem !important;
    clip-path: polygon(0 0, 100% 25%, 100% 100%, 0% 100%);
}

.statics-tag{
    text-transform: uppercase;
    color: var(--primary-color);
    top: .6rem;
}

.card-server{
    background-color: #1a1c25;
    border: none;
    display: grid;
    grid-template-columns: 4.5rem auto auto;
    align-items: center;
}

.server-image{
    background-image: url('https://cdn.discordapp.com/icons/628127590162366484/9e2bcb2d24cefa5f108b793a7fec5421.png?size=1024'); 
    background-repeat: no-repeat; 
    width: 56px; 
    height: 56px;
}

.server-info{
    color: #b0b4c7;
}

.verified-badge{
    position: relative;
    bottom: .1rem;
}

.stats-box{
    width: 48.6% !important;
    background-color: #1a1c25;
}

.stats-icon{
    font-size: 2.8rem;
    color: var(--primary-color);
}

.statics-title-sm{
    color: #b0b4c7;
}

::-webkit-scrollbar {
    width: 5px;
  }
  
  ::-webkit-scrollbar-track {
    background: #282a38;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #e23388;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #e23388;
  }


  .tippy-box[data-theme~='verified'] {
    background-color: #21232e;
    color: #fff;
  }

  .tippy-box[data-theme~='verified'][data-placement^='top'] > .tippy-arrow::before {
    border-top-color: #21232e;
  }
  .tippy-box[data-theme~='verified'][data-placement^='bottom'] > .tippy-arrow::before {
    border-bottom-color: #21232e;
  }
  .tippy-box[data-theme~='verified'][data-placement^='left'] > .tippy-arrow::before {
    border-left-color: #21232e;
  }
  .tippy-box[data-theme~='verified'][data-placement^='right'] > .tippy-arrow::before {
    border-right-color: #21232e;
  }

  /* Features */

  .img-1{
    box-shadow: -10px 22px 80px 25px #e2338886;
  }

  .features-main-title::after{
      content: '';
      display: flex;
      width: 15%;
      height: 2px;
      background-color: #ff4aa1;
      margin-top: 1%;
  }

  .feature-title{
      font-size: 2.4rem;
  }

  .feature-descreption{
    color: #b0b4c7;
    margin-top: 9%;
}

.feature-circle{
    background-color: var(--primary-color);
    padding: 12px;
    font-size: 1.8rem;
    -webkit-box-reflect: below 3px linear-gradient(transparent, #1f212d);
    line-height: 0.70em;
    animation: 2s linear 0s normal fetreflex;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    transition: 0.2s all;
    cursor: pointer;
}

.feature-circle:hover{
    transform: translateY(-5px);
    transition: 0.2s all;
}

@keyframes fetreflex {
    0%{
        box-shadow: #292b3a 0px 5px 35px 7px; 
    }

    50%{
        box-shadow: #292b3a 0px 5px 35px 12px;     
    }
    
    75%{
        box-shadow: #292b3a 0px 5px 35px 23px;     
    }

    100%{
        box-shadow: #292b3a 0px 5px 35px 33px;     
    }
}

/* Partners */

.partners-section{
    margin-top: 8%;
    background-color: #282a38;
}

.partner-card{
    background-color: #1d1f29;
    border-radius: 7px;
    transition: 0.2s all;
}

.partner-card:hover{
    transform: translateY(-5px);
    transition: 0.2s all;
}

.card-footer{
    border: none;
} 

.partner-banner{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 100%;
    display: block;
    position: relative;
    height: 135px;
    object-fit: fill;
    -webkit-user-drag: none;
}

.btn-view{
    background-color: var(--primary-color);
}

.btn-view:hover{
    background-color: #e23388;
}

.partner-descreption{
    color: #b0b4c7;
}

/* Footer */

footer{
    background-color: #1d1f29;
}

.footer-title{
    color: #e23388;
    font-size: 1.6rem;
}

.footer-links li{
    list-style: none;
}

.links-title{
    font-weight: 700;
    font-size: 1.1rem;
}

.link-footer{
    text-decoration: none;
    color: #b0b4c7;
    transition: 0.2s all;
}

.link-footer:hover{
    color: #ff4aa1;
    text-decoration: underline;
    transition: 0.2s all;
}

.btn-add{
    background-color: var(--primary-color);
    color: #fff;
    border-radius: 3px;
    padding: 12px 14px; 
    width: 70%;
    transition: 0.2s all;
}

.btn-add:hover{
    color: #fff;
} 

.pr-c{
    right: 6rem;
    text-decoration: none;
    color: #b0b4c7;
}

.pr-c:hover{
    color: #b0b4c7;
}


/****************************************************************/

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 50px;
  height: 50px;
  margin: 3px;
  border: 3px solid #ff4aa1;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ff4aa1 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



.parent {
    position: relative;
    top: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .image1 {
    position: relative;
    top: 0;
    left: 0;
  }
  .image2 {
    position: absolute;
    top: 10px;
    left: 225px;
  }
  .leave {
    position: relative;
    top: 40px;
    left: 250px;
    width: 340px
  }
  .join {
    position: absolute;
    top: 10px;
    left: 5px;
    width: 340px
  }
  
  @media only screen and (max-width: 1350px) {
      .parent {
        margin-right: 0px;
      }
      .left_side {
        margin-left: 0px;
      }
    }
  
    @media only screen and (max-width: 1144px) {
      .parent img {
        width: 285px;
      }
      .join {
        top: 10px;
        left: 10px;
      }
      .leave {
        top: 20px;
        left: 180px;
      }
      .join .leave {
        margin-top: 10px;
        width: 350px !important;
      }
    }
  
    @media only screen and (max-width: 972px) {
      .info_container {
        flex-direction: column;
      }
      
    }
  
    @media only screen and (max-width: 600px) {
      .parent img {
        width: 285px;
      }
      .join {
        top: 10px;
        left: 10px;
      }
      .leave {
        top: 20px;
        left: 200px;
      }
      .join .leave {
        margin-top: 10px;
        width: 300px !important;
      }
    }
  
    @media only screen and (max-width: 500px) {
      .parent img {
        width: 220px;
      }
      .join {
        top: 10px;
        left: 10px;
      }
      .leave {
        top: 20px;
        left: 150px;
      }
      .join .leave {
        margin-top: 10px;
        width: 230px !important;
      }
    }
  
    @media only screen and (max-width: 400px) {
      .parent img {
        width: 185px;
      }
      .join {
        top: 10px;
        left: 10px;
      }
      .leave {
        top: 20px;
        left: 130px;
      }
      .join .leave {
        margin-top: 10px;
        width: 230px !important;
      }
    }

  
    .images {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 100px;
    }
  
    .images img {
        width: 700px;
    }
  
    .main_image {
      position: absolute;
      width: 280px !important;
      margin-top: 20px;
    }
  
    .feature-title {
      font-size: 2.4rem;
    }
  
    .tst {
      width: 100%;
    }
  
    @media only screen and (max-width: 1350px) {
      .images {
        margin-right: 0px;
      }
      .left_side {
        margin-left: 0px;
      }
    }
  
    @media only screen and (max-width: 1144px) {
      .images img {
        width: 600px;
      }
  
      .main_image {
        width: 240px !important;
      }
    }
  
    @media only screen and (max-width: 972px) {
      .info_container {
        flex-direction: column;
      }
    }
  
    @media only screen and (max-width: 600px) {
      .images img {
        width: 500px;
      }
  
      .main_image {
        width: 190px !important;
      }
    }
  
    @media only screen and (max-width: 500px) {
      .images img {
        width: 400px;
      }
  
      .main_image {
        margin-top: 10px;
        width: 170px !important;
      }
      .banner {
        width: 230px
      }
    }
  
    @media only screen and (max-width: 400px) {
      .images img {
        width: 350px;
      }
  
      .main_image {
        margin-top: 10px;
        width: 150px !important;
      }
      .banner {
        width: 230px
      }
    }


    .lds-facebook {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
    }
    .lds-facebook div {
      display: inline-block;
      position: absolute;
      left: 8px;
      width: 16px;
      background: #fff;
      animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }
    .lds-facebook div:nth-child(1) {
      left: 8px;
      animation-delay: -0.24s;
    }
    .lds-facebook div:nth-child(2) {
      left: 32px;
      animation-delay: -0.12s;
    }
    .lds-facebook div:nth-child(3) {
      left: 56px;
      animation-delay: 0;
    }
    @keyframes lds-facebook {
      0% {
        top: 8px;
        height: 64px;
      }
      50%, 100% {
        top: 24px;
        height: 32px;
      }
    }
    
  