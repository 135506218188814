.commands-section{
    width: 55%;
    margin: 0 auto;
}

.commands-descreption{
   background-color: #16161b;
}

.command-name{
    background-color: #ff4aa1;
    clip-path: polygon(0 2%, 100% 0, 80% 100%, 0% 100%);
    height: 9vh;
    border: none;
    position: relative;
    bottom: .5px;
}

.command-card{
    width: 70%;
    background-color: #2c2d38;
    font-size: 1.1rem;
    transition: 0.2s all;
}

.command-card:hover{
    transform: translateY(-4px);
    transition: 0.2s all;
}

@media screen and (max-width: 700px){
  .commands-section{
        width: 99%;
        margin: 0 auto;
  }
  .command-card{
      width: 95% !important;
  }
}