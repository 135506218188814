.filter__container {
  background-color: #1b1d26;
  padding-left: 3rem;
  padding-right: 3rem;
  border-radius: 7px;
  padding-top: 1.5rem;
}

.main__desc,
.text-range {
  color: #9ca3c9;
  font-weight: 500;
}

.search__bar {
  background-color: #1f212d;
  width: 100%;
  border-radius: 30px;
  padding: 0.7rem;
  padding-left: 1.5rem;
}

.icon__search,
.icon__kite {
  color: #9ca3c9;
  position: relative;
  top: 4.5px;
}

.search__input {
  background-color: #1f212d;
  border: none;
  margin-left: 1rem;
  outline: none;
  color: #9ca3c9;
}

.icon__kite {
  position: relative;
  right: 5px;
  font-size: 1.1rem;
}

.descreption__tags {
  color: #83869b;
  font-size: 15px;
}

.tag {
  display: flex;
  background-color: #1f212d;
  color: #9ca3c9;
  padding: 0.2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 30px;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
}

.form-range {
  border-radius: 30px !important;
}

.discover__title {
  color: #9ca3c9;
  font-weight: 400;
}

.tag:not(:first-child) {
  margin-left: 0.5rem;
}

.server__card {
  border-radius: 7px;
  height: 100%;
}

.top-server {
  border: 1px solid #ff4aa1;
  border-radius: 8px;
  padding: 0.4rem !important;
  height: max-content;
}

.server__banner {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  display: block;
  position: relative;
  height: 155px;
  object-fit: fill;
  -webkit-user-drag: none;
}

.card {
  /* background-color: #181921; */
  user-select: none;
}
.card2 {
background-color: #181921; 
user-select: none;
position: relative;
display: flex;
flex-direction: column;
min-width: 0;
word-wrap: break-word;
background-clip: border-box;
border: 1px solid rgba(0,0,0,.125);
border-radius: 0.25rem;
}

.servers__container {
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  display: grid;
  grid-gap: 20px;
  align-items: stretch;
}

.server__icon {
  position: absolute;
  top: 100px;
  border-radius: 15px;
  border: 6px solid #181921;
}

.server__name {
  color: #9ca3c9;
}

.server_descreption {
  color: #8d91a7ce;
}

.votes__number {
  position: absolute;
  top: 0;
  background-color: #1f212d;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #9ca3c9;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 10px;
  margin-right: 8px;
}

.badges_tag {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #1f212d;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 10px;
  margin-right: 8px;
  height: 2vh;
  padding: 0.7rem;
  color: #9ca3c9;
  font-size: 14px;
  border-radius: 4px;
}

.badge_icon {
  position: relative;
  bottom: 10px;
  margin-left: 8px;

  /* height: 18px;
  width: 19px; */
}

.server__main__tag {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
  /* padding: 5px; */
}


.server__main__tag_1 {
  background-color: #20222b;
  color: #848796;
  margin-left: 10%;
  padding: 0.2rem;
  border-radius: 4px;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  font-size: 14px;
  position: relative;
  top: 2px;
}

.server__main__tag_2 {
  background-color: #20222b;
  color: #848796;
  /* margin-left: -10%; */
  padding: 0.2rem;
  border-radius: 4px;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  font-size: 14px;
  position: relative;
  top: 2px;
}

.card-footer {
  border-top: none;
}

.hidden {
  display: none;
}

.btn-join {
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 7px;
  padding: 6px 18px;
  transition: 0.2s all;
}

.btn-vote {
  background: #20222b;
  color: #fff;
  border-radius: 7px;
  padding: 6px 18px;
  transition: 0.2s all;
}


.btn-join:hover{
    transition: 0.1s all;
    color: #fff;
    background-color: #e23388;
}

.btn-vote:hover{
    transition: 0.1s all;
    color: #fff;
    background-color: #252730;
}

.range .thumb::after {
  background: #ff4aa1;
}

.btn-range {
  position: relative;
  top: 1rem;
  width: 60%;
  color: #83869b;
}

.form-range::-webkit-slider-thumb {
  background: #ff4aa1;
  border: 2px solid #ff4aa1;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: #9ca3c9;
  border-radius: 25px;
  height: 5px;
}

@media screen and (max-width: 2800px) {
  .container {
    width: 1320px !important;
  }
  .container2 {
    width: 60% !important;
    margin-right: auto;
    margin-left: auto;
  }
  .filter__container {
    width: 100%;
  }
}

@media screen and (max-width: 1540px) {
  .container {
    width: 70% !important;
  }
  .container2 {
    width: 90% !important;
    margin-right: auto;
    margin-left: auto;
  }
  .filter__container {
    width: 100%;
  }
}

@media screen and (max-width: 1278px) {
  .container {
    width: 80% !important;
  }
  .container2 {
    width: 90% !important;
    margin-right: auto;
    margin-left: auto;
  }
  .filter__container {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .container {
    width: 80% !important;
  }
  .container2 {
    width: 90% !important;
    margin-right: auto;
    margin-left: auto;
  }
  .filter__container {
    width: 100%;
  }
}

@media screen and (max-width: 1156px) {
  .container {
    width: 85% !important;
  }
  .container2 {
    width: 90% !important;
    margin-right: auto;
    margin-left: auto;
  }
  .filter__container {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .container {
    width: 85% !important;
  }
  .container2 {
    width: 90% !important;
    margin-right: auto;
    margin-left: auto;
  }
  .filter__container {
    width: 100%;
  }
}

@media screen and (max-width: 1062px) {
  .server__main__tag_1, .server__main__tag_2 {
    position: relative;
    /* right: 17px; */
    font-size: 14px;
    
  }
}

@media screen and (max-width: 760px) {
  .server__main__tag_1, .server__main__tag_2 {
    position: relative;
    /* right: 20px !important; */
    top: 6px !important;
    font-size: 11px !important;
    
  }
}

@media screen and (max-width: 972px) {
  .container {
    width: 100%;
  }
  .container2 {
    width: 95% !important;
    margin-right: auto;
    margin-left: auto;
  }
  .server__main__tag_1, .server__main__tag_2 {
    position: relative;
    /* right: 17px; */
    font-size: 10px;
    
  }

  .filter__container {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .container {
    width: 95% !important;
  }
  .container2 {
    width: 70% !important;
    margin-right: auto;
    margin-left: auto;
  }
  .filter__container {
    height: 63vh;
  }
  
  .all_main_tags {
    position: absolute;
    right: 0;
    transform: translateY(-40px);
    margin-right: 1rem;
  }
}

@media screen and (max-width: 700px) {
  .container {
    width: 95% !important;
  }
  .container2 {
    width: 90% !important;
    margin-right: auto;
    margin-left: auto;
  }
}

::placeholder {
  color: #9ca3c9;
  opacity: 0.6;
}

:-ms-input-placeholder {
  color: #9ca3c9;
  opacity: 0.6;
}

::-ms-input-placeholder {
  color: #9ca3c9;
  opacity: 0.6;
}
