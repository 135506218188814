@media screen and (max-width: 991px){
    .navbar-collapse{
        margin-top: 3%;
        position: relative;
        right: 1.5rem;
    }    
    .active-nav-item{
        border-bottom: none;
    }
    .btn-primary-custom{
        margin-top: 3%;
    }

    .staics-section{
        clip-path: none;
    }

    .st-row{
        width: 90% !important;
    }
    .stats-box{
      width: 90% !important;
    }
}


@media screen and (max-width: 1411px){
    .st-row{
        width: 90% !important;
    }
    .stats-box{
      width: 90% !important;
    }
}

@media screen and (max-width: 700px){
    .footer-links{
        position: relative;
        right: 2rem;
    }   

    .pr-c{
        right: 16.8rem !important;
    }
}